<template>
  <div class="tool-container text">
   <div class="hstack">
        <section class="cropper-area" v-show="showCropTool">
          <vue-cropper
            ref="cropper"
            :aspect-ratio="4 / 3"
            :src="imgSrc"
            alt="Source Image"
            :viewMode="2"
            preview=".preview"
            dragMode="crop"
            @ready="cropImage"
            @cropend="cropImage"
            :img-style="{ width: '400px', height: '300px' }"
          >
          </vue-cropper>
        </section>
        <div class="vstack" v-show="showCropTool">
            <section class="preview-area">
              <div class="cropped-image">
                <img
                  v-if="cropImg"
                  :src="cropImg"
                  alt="Cropped Image"
                />
                <div v-else class="crop-placeholder" />
              </div>
            </section>
            <div style="color: #007ed9; margin-left: 1rem; line-height: 2.2rem;">将以上截图标记上传为：<span><sea-button @action="showUploadImgs()" class="custombtn">查看已上传列表</sea-button></span></div>
            <div class="hstack" v-if="buttons.length > 0">
              <div class="button-area">
                <sea-button v-for="(button, index) in buttons" :key="index" @action="doOcr(button)">{{ button.m }}</sea-button>
              </div>
            </div>
        </div>
        <div class="hstack" v-show="buttons.length > 0">
              <div class="saved-image" v-for="(img, index) in buttons" :key="index" v-if="img.url" @click="lightbox(img)">
                <img :src="img.url" /><br />
                <span>{{ img.m }}</span>
              </div>
        </div>
    </div>
  </div>
</template>

<style lang="scss">
.tool-container {

  height: 35vh;
  background-color: azure;

  .cropper-area {
    width: 30vw;
    height: 20vh;
    max-height: 98%;
  }
  
  .preview-area {
    width:10vw;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }
  
  .cropped-image {
    border: 2px dotted #f5222d;
    img {
        width: 10vw;
        max-width: 100%;
    }
  }
  
  .button-area {
    margin-top: 0.2rem;
    margin-left: 1rem;
    width: 30rem;
    button {
        line-height: 0.1rem;
        height: 1.8rem;
        margin: 0.2rem 0.2rem;
    }
  }

  .saved-image {
    /*border: 1px solid #eee;*/
    padding: 0.5rem;
    height: 10rem;
    img {
        width: 8rem;
        height: 6rem;
    }
    span {
        color: #1e89f6;
    }
  }
  
  .custombtn {
        line-height: 0.1rem;
        height: 1.8rem;
        margin: 0.2rem 0.2rem;
        background: #109114;
        float: right;
  }

}
</style>

<script>
import { messages } from "@/lib/emitter"
import SeaButton from "../ui/sea-button"
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import { Logger } from "../lib/logger"
const log = Logger("app:app-tool")

export default {
  name: "app-tool",
  components: { VueCropper, SeaButton },
  data() {
    return {
      imgSrc: "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAA4QAAAH0BAMAAACX3f7gAAAAElBMVEX/////+v///f//+////P///v+82+bFAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACV0lEQVR4nO3ZsY6bMBwHYBPCjuO7Pai9PZQXSIbud1Xf/1lqYyJdUrp0gZO+bwGb/2DpJ4xtQgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGAThzH+yJdvMZ3vjbHPHbc+NDHG/qmC/RnjGK/hEFN8CWGYGx+nELp4Dk2apsu9c6lgdw45quE1NKd89x5qo9y1JdDLXPJQwe6UqNo0pzVeDmlu5Lsw5Bl0uJaKpbNWbDxaVuSXK79dYbzmxPqSZ27kmbTMo+F2LhVLZ63YcKT8wxJhSeyjb+aPYAjHWHIL4zS91YruXrHxaFnxKcLmtTRC+eDFecrMC9K8jlk6a8WmY2VVWxYrf0U4lHZ4C7/GFxHuXRfzlmElwmt93EYR7t7vmL6npwi7OCxhdfFdhF9Ae3pazrTpmJZnNULLmZ3Le4XHTUXuWGbS/BbaVOxfl+N62Np39TymOEZb+y9gSGUzX47a5uxO8wFNW2fS3Fo6a8W2I2XVsZxhPx5z3/p6zB2nsi90zL13bfpZLp9+Ns2Ha2UmzbuN8pfJzyYAAAAA/tcf6SotfLwuezoAAAAASUVORK5CYII=",
      cropImg: "",
      result: "",
      buttons: [],
      button_dict: [],
      showCropTool: true,
      showUpload: false,
    }
  },
  created() {
    //console.log('app-ocr created')
  },
  mounted() {
    //console.log('app-ocr mounted')
    try{
        const buttons = JSON.parse(this.state.param)
        if(typeof buttons === 'object' && buttons.length > 0) {
            this.buttons = buttons 
            buttons.forEach((item, index) => {
                this.button_dict[item.t] = index
            })
            
            //console.log(this.button_dict)
        }
    }catch(e) {
        //console.log(e)
    }
    
    messages.on("newSnapshot", (img) => {
      this.$refs.cropper.reset()
      this.imgSrc = img
      this.$refs.cropper.replace(img)
    })
    
    messages.on("ToggleCropTool", (state) => {
      if(state !== -1) {
        this.showCropTool = ["1", "true", "yes"].includes(state.toString().toLocaleLowerCase())
        this.state.showCropTool = this.showCropTool
      }else {
        this.showCropTool = !this.showCropTool
        this.state.showCropTool = this.showCropTool
      }
    })
    
  },
  beforeDestroy() {
    //console.log('app-ocr beforeDestroy')
  },
  methods: {
    doOcr(button) {
    var payload = {
        'id': this.state.caseid,
//       'caseid': this.state.caseid,
        'tabId': this.state.tabid,
        'qybh': this.state.qybh,
        'tabname': this.state.tabname,
    }
    
      payload[button.t] = {}
      
      if(payload.id === '' || payload.tabId === '' || payload.qybh === '' || payload.tabname === '' ) {
        messages.emit("layerAlert", '缺少参数')
        return false
      }
      
      let imgUrl = ''
      this.uploadImage(button).then( res => {
            //console.log(res)
            this.result = JSON.stringify(res)
            if(res.code === 200 && res.data?.url) {
                imgUrl = res.data.url
                return res.data.url
            }else{
                throw new Error('上传出错')
            }
      }).then(res => {
        //console.log(res)
        if(!button.hasOwnProperty('x') || button.x === undefined || parseInt(button.x) === 0 ) {
          return ''
        }
        
        //console.log(button)
        
        let m = parseInt(button.x)
        let mode = ''
        switch(m) {
            case 1:
                mode = 'IdCard'
                break
            case 2:
                mode = 'drivingLicense'
                break
            default:
                mode = ''
                break
        }
        return this.baiduOcr(mode)
      }).then(res => {
        //console.log(res)
        if(button.hasOwnProperty('x') && parseInt(button.x) > 0 ) {
          if(!res?.words_result_num) {
            throw new Error('识别出错,请截取有效区域')
          }
        }
      
      payload[button.t]['img'] = imgUrl.replace('_small_', '')
      if(button.hasOwnProperty('pic') && button.pic !== "") {
        payload[button.pic] = [imgUrl.replace('_small_', '')]
      }
      
      if(res.words_result) {
         payload[button.t]['data'] = res.words_result
         this.buttons[this.button_dict[button.t]]['ocr_result'] = res.words_result
      }
      
      this.buttons.splice(this.button_dict[button.t], 1, {...this.buttons[this.button_dict[button.t]], url: imgUrl.replace('http://file.3-3.me', window.location.origin)})

      Object.keys(payload).forEach((key) => {
        if(!payload[key]) delete payload[key]
        if(typeof payload[key] === 'object') {
            payload[key] = JSON.stringify(payload[key]).toString()
        }
      })
      
      //console.log(payload)

       return this.$http.post("/updateAiVueData_flowable", payload ).then( res => {
            //console.log(res)
            this.result = JSON.stringify(res)
            if(res?.result === 'succ') {
                if(!button.hasOwnProperty('x') ) {
                    messages.emit("layerMsg", button.m + '上传成功')
                }
                return ''
            }else {
                throw new Error('上传出错')
            }
          })
       //payload[button.t]['data']
 
      }).then(() => {
        //console.log(button)
        if(this.state.mxtabid == "") {
            messages.emit("layerMsg", button.m + '上传成功')
            return ''
        }
        if(button.hasOwnProperty('x') && parseInt(button.x) > 0 ) {
           let param = {
            'accident_id': this.state.caseid,
            'tabId': this.state.mxtabid,
            'tabname': this.state.mxtabname,
            'qybh': this.state.qybh,
            'nowuserid': '0',
            'nowusername': '0',
            'flowstate': '0',
          }
          
           let m = parseInt(button.x)
           let img_url_key = ''
           let img_ocr_key = ''
           
           switch(m) {
                case 1:
                    img_url_key = 'idcard_img'
                    img_ocr_key = 'item_01'
                    param['party_name'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.姓名.words ?? ""
                    param['idcard'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.公民身份号码.words ?? ""
                    param['address'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.住址.words ?? ""
                    break
                case 2:
                    img_url_key = 'driver_card_img'
                    img_ocr_key = 'item_02'
                    param['party_name'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.姓名.words ?? ""
                    param['idcard'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.证号.words ?? ""
                    param['address'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.住址.words ?? ""
                    //param['plate_no'] = this.buttons[this.button_dict[button.t]]?.ocr_result?.车牌号码.words
                    break
                default:
                    img_url_key = ''
                    img_ocr_key = ''
                    break
           }
          
          if(img_url_key) {
            param[img_url_key] = [this.buttons[this.button_dict[button.t]]?.url]
            param[img_ocr_key] = this.buttons[this.button_dict[button.t]]?.ocr_result ?? {}
          }
          
          //console.log(param)
          
          Object.keys(param).forEach((key) => {
            if(!param[key]) delete param[key]
            if(typeof param[key] === 'object') {
                param[key] = JSON.stringify(param[key]).toString()
            }
          })
          
          //console.log(param)

           return this.$http.post("/addAiVueData_flowable", param).then( res => {
                //console.log(res)
                this.result = JSON.stringify(res)
                if(res?.result === 'succ') {
                    messages.emit("layerMsg", '上传成功')
                    return ''
                }else {
                    throw new Error('上传出错')
                }
              })
        }else {
            return ''
        }
      }).catch( err => {
        //console.log(err)
        if(err?.message) {
            messages.emit("layerAlert", err.message)
        }
      })
     
    },
    showUploadImgs() {
        if(this.showUpload) {
            messages.emit("switchMode", "doc") //default=doc
            this.showUpload = false
            messages.emit("unloadPage")
        }else {
            this.showUpload = true
            messages.emit("switchMode", "page")
            
            let urlParams = this.state.urlParams
            let query = []
            for(let [key, value] of urlParams.entries()) {
              //console.log(key, value)
              query.push(key + '=' + value)
            }
            let query_string = query.join('&')
            console.log(query_string)
             
            messages.emit("loadPage", 'https://bbc.3-3.me/aiFlowTest/#/aicode?isblend=1&sf=accident_id&hidebar=1&jiami=1&' + query_string)
         
            //messages.emit("loadPage", 'https://bbc.3-3.me/aiFlowTest/#/aicode?isblend=1&tabid=' + this.state.mxtabid + '&qybh=' + this.state.qybh + '&accident_id=' + this.state.caseid + '&sf=accident_id&hidebar=1&jiami=1')
        }
    },
    lightbox(img) {
        let html = '<img src="' + img.url.replace('_small_', '') + '" />'
        this.$layer.alert(html, {
            title: img.m
          }, layerid => {
            this.$layer.close(layerid)
          }
        )
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      if(this.cropImg === 'data:,') {
        this.cropImg = ''
      }
    },
    baiduOcr(mode) {
      return this.$http.post("/baidu", {
          mode: mode,
          img: this.cropImg
      })
    },
    uploadImage(button) {
          let payload = new FormData()
          if(button.hasOwnProperty('x') && parseInt(button.x) > 0 ) {
            payload.append('upload', this.convertBase64UrlToBlob(this.cropImg), 'snap.jpg')
          }else{
            payload.append('upload', this.convertBase64UrlToBlob(this.imgSrc), 'orig.jpg')
          }
          return this.$http.post("/file/uploadFile_v2?appName=sgk", payload, {headers: {'Content-Type': 'multipart/form-data'}})
    },
    convertBase64UrlToBlob(urlData) {
        let arr = urlData.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
        while(n--){
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], {type: mime})
    }
  },
}
</script>
